export enum DamageCategoryOptions {
  GROUND_CABLE = 'damage_report_form.step_one.category_ground',
  AIR_CABLE = 'damage_report_form.step_one.category_air',
  CABINET = 'damage_report_form.step_one.category_cabinet',
  TECH_BUILDING = 'damage_report_form.step_one.category_tech_building',
  POLE = 'damage_report_form.step_one.category_post',
  WELL = 'damage_report_form.step_one.category_well',
}

export enum GuideCorrectOptions {
  YES = 'damage_report_form.step_one.yes',
  NO = 'damage_report_form.step_one.no',
  UNKNOWN = 'damage_report_form.step_one.do_not_know',
}

export enum GroundCableFiberOptions {
  FIBER_IN_SEWER = 'damage_report_form.step_one.fiber_in_sewer',
  SEWER_ONLY = 'damage_report_form.step_one.sewer_only',
}

export enum AirCableFiberOptions {
  FIBER_IN_SEWER = 'damage_report_form.step_one.fiber_in_sewer',
  AIR_CABLE_SEWER_ONLY = 'damage_report_form.step_one.air_cable_sewer_only',
}

export enum AirCableCopperOptions {
  PLASTIC_SHEATHED = 'damage_report_form.step_one.plastic_sheathed',
  AIR_CABLE_ABOVE_CHANNELING = 'damage_report_form.step_one.air_cable_above_channeling',
}

export enum CopperOptions {
  PLASTIC_SHEATHED = 'damage_report_form.step_one.plastic_sheathed',
  LEAD_SHEATHED = 'damage_report_form.step_one.lead_sheathed',
  ABOVE_CHANNELING = 'damage_report_form.step_one.above_channeling',
}

export enum DamageTypes {
  WIRE_TORN = 'damage_report_form.step_one.broken_wire',
  WIRE_HANGING = 'damage_report_form.step_one.low_hanging_wire',
  WIRE_TREE = 'damage_report_form.step_one.tree_on_wire',
  OTHER = 'damage_report_form.step_one.miscellaneous',
}

export enum PoleDamageTypes {
  POLE_BROKEN = 'damage_report_form.step_one.broken_pole',
  POLE_LEANING = 'damage_report_form.step_one.leaning_pole',
  OTHER = 'damage_report_form.step_one.miscellaneous',
}

export enum GroundCableDamageTypes {
  OTHER = 'damage_report_form.step_one.miscellaneous',
}

export enum PoleCopperOptions {
  FIBER = 'damage_report_form.step_one.pole_fiber',
  COPPER = 'damage_report_form.step_one.pole_copper',
  LREAD_IN_SEWERAGE = 'damage_report_form.step_one.lead_in_sewerage',
  UNKNOWN = 'damage_report_form.step_one.pole_unknown',
}

export enum CabinetTypes {
  FIBER = 'damage_report_form.step_one.cabinet_fiber',
  COPPER = 'damage_report_form.step_one.cabinet_copper',
  UNKNOWN = 'damage_report_form.step_one.cabinet_unknown',
}

export enum CabinetDamageTypes {
  CABINET_OPEN = 'damage_report_form.step_one.cabinet_open',
  CABINET_HIT = 'damage_report_form.step_one.cabinet_crashed',
  CABINET_MISSING_HATCH = 'damage_report_form.step_one.cabinet_missing_hatch',
  CABINET_LEANING = 'damage_report_form.step_one.cabinet_leaning',
  OTHER = 'damage_report_form.step_one.cabinet_miscellaneous',
}

export enum TubingType {
  YES = 'YES',
  NO = 'NO',
}

export enum CableSheatingType {
  PLASTIC = 'PLASTIC',
  LEAD = 'LEAD',
  BOTH = 'BOTH',
}

export enum CableCategoryType {
  GROUND_CABLE = 'GROUND_CABLE',
  AIR_CABLE = 'AIR_CABLE',
  CABINET = 'CABINET',
  TECH_BUILDING = 'TECH_BUILDING',
  POLE = 'POLE',
  WELL = 'WELL',
}

export enum CopperOptionType {
  PLASTIC_SHEATHED = 'PLASTIC_SHEATHED',
  LEAD_SHEATHED = 'LEAD_SHEATHED',
  ABOVE_CHANNELING = 'ABOVE_CHANNELING',
  FIBER = 'FIBER',
  COPPER = 'COPPER',
  LREAD_IN_SEWERAGE = 'LREAD_IN_SEWERAGE',
}
