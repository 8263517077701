// eslint-disable-next-line import/order
import {
  TeliaCol,
  TeliaDatePicker,
  TeliaDivider,
  TeliaRadioButton,
  TeliaRow,
  TeliaTextInput,
  TeliaTextarea,
  TeliaToggle,
} from '@teliads/components/react';
import '../StepOneForm.scss';

import { useTranslation } from 'react-i18next';

import { useDamageReportForm } from '../../../../contexts/damage-report-form.context';
import { MAX_TEXTAREA_LENGTH } from '../../../../utils/constants';
import { PoleCopperOptions, PoleDamageTypes } from '../models';

import Paragraph from 'components/voca/paragraph/Paragraph';

function Pole({ updateInput }: any) {
  const { stepOne } = useDamageReportForm();
  const currentDate = new Date().toLocaleDateString('sv-SE', { timeZone: 'CET' });
  const { t } = useTranslation();

  return (
    <>
      <TeliaRow class="step-one-form__radio-section">
        <TeliaCol width={12} widthMd={5}>
          <Paragraph variant="overline-100" className="step-one-form__divider">
            * 2. {t('damage_report_form.step_one.type_of_wire_in_pole')}
          </Paragraph>
          <TeliaDivider />

          <div className="step-one-form__radio-buttons">
            {Object.entries(PoleCopperOptions).map(([key, value]) => (
              <TeliaRadioButton
                key={key}
                checked={stepOne.poleCableType === key}
                onClick={() => updateInput('poleCableType', key)}
              >
                {t(value)}
              </TeliaRadioButton>
            ))}
          </div>
        </TeliaCol>

        <TeliaCol width={12} widthMd={5} className="step">
          <Paragraph variant="overline-100" className="step-one-form__divider">
            * 3. {t('damage_report_form.step_one.damage_type')}
          </Paragraph>
          <TeliaDivider />

          <div className="step-one-form__radio-buttons">
            {Object.entries(PoleDamageTypes).map(([key, value]) => (
              <TeliaRadioButton
                key={key}
                checked={stepOne.damageType === key}
                onClick={() => updateInput('damageType', key)}
              >
                {t(value)}
              </TeliaRadioButton>
            ))}
          </div>
        </TeliaCol>
      </TeliaRow>

      <TeliaRow>
        <TeliaCol width={12} widthMd={6}>
          <TeliaTextarea
            maxlength={MAX_TEXTAREA_LENGTH}
            required
            requiredErrorMessage={t('error_message.required')}
            label={`4. ${t('damage_report_form.step_one.damage_description')}`}
            value={stepOne.description}
            onInput={(e) => updateInput('description', (e.target as HTMLInputElement).value.trim())}
            rows={2}
          />
        </TeliaCol>
      </TeliaRow>

      <TeliaRow>
        <TeliaCol width={12} widthMd={6}>
          <TeliaTextInput
            type="text"
            maxlength={MAX_TEXTAREA_LENGTH}
            value={stepOne.cableWidth}
            placeholder="120 mm / 3 Par"
            onInput={(e) => updateInput('cableWidth', (e.target as HTMLInputElement).value.trim())}
            label={`5. ${t('damage_report_form.step_one.marking')}`}
          />
        </TeliaCol>
      </TeliaRow>

      <TeliaRow class="step-one-form__date-toggle">
        <TeliaCol width={12} widthMd={4}>
          <TeliaDatePicker
            required
            dataTestid="date-picker"
            invalidErrorMessage={t('error_message.invalid_date_format')}
            requiredErrorMessage={t('error_message.required')}
            disabled={stepOne.dateOfIncidentDisabled}
            value={stepOne.dateOfIncident}
            max={currentDate}
            onVocaChange={(e) => updateInput('dateOfIncident', e.detail.value)}
            label={`6. ${t('damage_report_form.step_one.damage_date')}`}
          />
        </TeliaCol>
        <TeliaCol width={3} widthMd={4}>
          <TeliaToggle
            dataTestid="date-toggler"
            label={t('damage_report_form.step_one.do_not_know')}
            labelPosition="after"
            selected={stepOne.dateOfIncidentDisabled}
            onVocaChange={(e) => {
              updateInput('dateOfIncidentDisabled', e.detail.value);
              updateInput('dateOfIncident', currentDate);
            }}
          />
        </TeliaCol>
      </TeliaRow>
    </>
  );
}

export default Pole;
