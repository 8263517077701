import '@teliads/components/dist/telia-component-library/telia-component-library.css';
import { registerIconsBundle } from '@teliads/components/icons';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { polyfill } from 'seamless-scroll-polyfill';
import { HeaderProvider } from 'telia-navigation';

import { ApolloCustomProvider } from './apollo';
import './index.scss';
import './fonts.scss';
import { reportWebVitals } from './reportWebVitals';

import App from 'App';
import { initI18n } from 'i18n';

polyfill();

const DEFAULT_TITLE = 'Telia Natet';
const DEFAULT_DESCRIPTION = 'Telia Natet description';
const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const mobileDetectorDiv = document.createElement('div');
mobileDetectorDiv.setAttribute('data-mobile-detector', '');
document.body.prepend(mobileDetectorDiv);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <ApolloCustomProvider>
          <HeaderProvider>
            <Helmet>
              <title>{DEFAULT_TITLE}</title>
              <meta name="description" content={DEFAULT_DESCRIPTION} />
            </Helmet>

            <App />
          </HeaderProvider>
        </ApolloCustomProvider>
      </QueryClientProvider>
    </HelmetProvider>
  </React.StrictMode>
);

initI18n();
registerIconsBundle();
reportWebVitals();
