import { TeliaCol, TeliaForm, TeliaHeading, TeliaRow } from '@teliads/components/react';
import { useTranslation } from 'react-i18next';
import './StepSummary.scss';

import { CableCategoryType, DamageCategoryOptions, GuideCorrectOptions } from '../step-one/models';

import SummaryList from './SummaryList';

import Card from 'components/custom/card/Card';
import { useDamageReportForm } from 'contexts/damage-report-form.context';

export default function StepSummary() {
  const { t } = useTranslation();
  const { stepOne, stepTwo, stepThree } = useDamageReportForm();

  const poleSummaryItems = [
    {
      leftSideText: t('damage_report_form.step_summary.what_is_the_damage'),
      rightSideText: t(
        DamageCategoryOptions[stepOne.damageCategory as keyof typeof DamageCategoryOptions]
      ),
      isRendered: true,
    },
    {
      leftSideText: t('damage_report_form.step_summary.type_of_cabinet'),
      rightSideText: t(`damage_report_form.step_summary.${stepOne.poleCableType}`),
      isRendered: !!stepOne.poleCableType,
    },
    {
      leftSideText: t('damage_report_form.step_summary.damage'),
      rightSideText: t(`damage_report_form.step_summary.${stepOne.damageType}`),
      isRendered: !!stepOne.damageType,
    },
    {
      leftSideText: t('damage_report_form.step_summary.description_of_location'),
      rightSideText: stepOne.description,
      isRendered: true,
    },
    {
      leftSideText: t('damage_report_form.step_summary.size_of_wire'),
      rightSideText: stepOne.cableWidth,
      isRendered: !!stepOne.cableWidth,
    },
    {
      leftSideText: t('damage_report_form.step_summary.damage_date'),
      rightSideText: stepOne.dateOfIncident,
      isRendered: true,
    },
  ];

  const airCableSummaryItems = [
    {
      leftSideText: t('damage_report_form.step_summary.what_is_the_damage'),
      rightSideText: t(
        DamageCategoryOptions[stepOne.damageCategory as keyof typeof DamageCategoryOptions]
      ),
      isRendered: true,
    },
    {
      leftSideText: t('damage_report_form.step_summary.type_of_wire'),
      rightSideText: t(
        `damage_report_form.step_summary.${Object.values(stepOne.cableType).filter(Boolean)[0]}`
      ),
      isRendered: !!stepOne.cableType.fiber || !!stepOne.cableType.copper,
    },
    {
      leftSideText: t('damage_report_form.step_summary.damage'),
      rightSideText: t(`damage_report_form.step_summary.${stepOne.damageType}`),
      isRendered: !!stepOne.damageType,
    },
    {
      leftSideText: t('damage_report_form.step_summary.damage_description'),
      rightSideText: stepOne.description,
      isRendered: true,
    },
    {
      leftSideText: t('damage_report_form.step_summary.wire_size'),
      rightSideText: stepOne.cableWidth,
      isRendered: !!stepOne.cableWidth,
    },
    {
      leftSideText: t('damage_report_form.step_summary.damage_date'),
      rightSideText: stepOne.dateOfIncident,
      isRendered: true,
    },
  ];

  const cabinetSummaryItems = [
    {
      leftSideText: t('damage_report_form.step_summary.what_is_the_damage'),
      rightSideText: t(
        DamageCategoryOptions[stepOne.damageCategory as keyof typeof DamageCategoryOptions]
      ),
      isRendered: true,
    },
    {
      leftSideText: t('damage_report_form.step_summary.type_of_cabinet'),
      rightSideText: t(`damage_report_form.step_summary.${stepOne.cabinetType}`),
      isRendered: !!stepOne.cabinetType,
    },
    {
      leftSideText: t('damage_report_form.step_summary.damage'),
      rightSideText: t(`damage_report_form.step_summary.${stepOne.damageType}`),
      isRendered: !!stepOne.damageType,
    },
    {
      leftSideText: t('damage_report_form.step_summary.ev_marking'),
      rightSideText: stepOne.description,
      isRendered: true,
    },
    {
      leftSideText: t('damage_report_form.step_summary.size_of_wire'),
      rightSideText: stepOne.cableWidth,
      isRendered: !!stepOne.cableWidth,
    },
    {
      leftSideText: t('damage_report_form.step_summary.damage_date'),
      rightSideText: stepOne.dateOfIncident,
      isRendered: true,
    },
  ];

  const groundCableSummaryItems = [
    {
      leftSideText: t('damage_report_form.step_summary.what_is_the_damage'),
      rightSideText: t(
        DamageCategoryOptions[stepOne.damageCategory as keyof typeof DamageCategoryOptions]
      ),
      isRendered: true,
    },
    {
      leftSideText: t('damage_report_form.step_summary.type_of_wire'),
      rightSideText: t(
        `damage_report_form.step_summary.${Object.values(stepOne.cableType).filter(Boolean)[0]}`
      ),
      isRendered: !!stepOne.cableType.fiber || !!stepOne.cableType.copper,
    },
    {
      leftSideText: t('damage_report_form.step_summary.were_wiring_instructions_correct'),
      rightSideText: t(
        GuideCorrectOptions[stepOne.isGuideCorrect as keyof typeof GuideCorrectOptions]
      ),
      isRendered: true,
    },
    {
      leftSideText: t('damage_report_form.step_summary.description_of_location'),
      rightSideText: stepOne.description,
      isRendered: true,
    },
    {
      leftSideText: t('damage_report_form.step_summary.size_of_wire'),
      rightSideText: stepOne.cableWidth,
      isRendered: !!stepOne.cableWidth,
    },
    {
      leftSideText: t('damage_report_form.step_summary.management_control_case_number'),
      rightSideText: stepOne.managementControlCaseNumber,
      isRendered: !!stepOne.managementControlCaseNumber,
    },
    {
      leftSideText: t('damage_report_form.step_summary.damage_date'),
      rightSideText: stepOne.dateOfIncident,
      isRendered: true,
    },
    {
      leftSideText: t('damage_report_form.step_summary.replacement_date'),
      rightSideText: stepOne.dateOfRefill,
      isRendered: !!stepOne.dateOfRefill,
    },
  ];

  const placeSummaryItems = [
    {
      leftSideText: t('damage_report_form.step_summary.coordinates'),
      rightSideText: Object.values(stepTwo.position).join(', '),
      isRendered: !!stepTwo.position.latitude && !!stepTwo.position.longitude,
    },
    {
      leftSideText: t('damage_report_form.step_summary.address'),
      rightSideText: stepTwo.address,
      isRendered: !!stepTwo.address,
    },
    {
      leftSideText: t('damage_report_form.step_summary.description_of_location'),
      rightSideText: stepTwo.description,
      isRendered: true,
    },
  ];

  const reporterSummaryItems = [
    {
      leftSideText: t('damage_report_form.step_summary.name'),
      rightSideText: `${stepThree.author.firstName} ${stepThree.author.lastName}`,
      isRendered: true,
    },
    {
      leftSideText: t('damage_report_form.step_summary.company_name'),
      rightSideText: stepThree.author.organization,
      isRendered: !!stepThree.author.organization,
    },
    {
      leftSideText: t('damage_report_form.step_summary.phone_number'),
      rightSideText: stepThree.author.phone,
      isRendered: true,
    },
    {
      leftSideText: t('damage_report_form.step_summary.email_address'),
      rightSideText: stepThree.author.email,
      isRendered: true,
    },
  ];

  const instructorsOnSiteSummaryItems = [
    {
      leftSideText: t('damage_report_form.step_summary.name'),
      rightSideText: stepThree.onSite.isSameAsAuthor
        ? `${stepThree.author.firstName} ${stepThree.author.lastName}`
        : `${stepThree.onSite.firstName} ${stepThree.onSite.lastName}`,
      isRendered: true,
    },
    {
      leftSideText: t('damage_report_form.step_summary.phone_number'),
      rightSideText: stepThree.onSite.isSameAsAuthor
        ? stepThree.author.phone
        : stepThree.onSite.phone,
      isRendered: true,
    },
    {
      leftSideText: t('damage_report_form.step_summary.email_address'),
      rightSideText: stepThree.onSite.isSameAsAuthor
        ? stepThree.author.email
        : stepThree.onSite.email,
      isRendered: true,
    },
  ];
  function renderDamageSummaryItems() {
    switch (stepOne.damageCategory) {
      case CableCategoryType.POLE:
        return poleSummaryItems;
      case CableCategoryType.AIR_CABLE:
        return airCableSummaryItems;
      case CableCategoryType.CABINET:
        return cabinetSummaryItems;
      default:
        return groundCableSummaryItems;
    }
  }

  return (
    <Card dataTestid="step-summary-card">
      <TeliaForm name="step-summary" className="step-summary">
        <TeliaRow>
          <TeliaCol width={12} widthLg={8} className="step-summary__main-info">
            <TeliaHeading tag="h1" variant="title-200" className="step-summary__title">
              {t('damage_report_form.step_summary.title')}
            </TeliaHeading>
          </TeliaCol>
        </TeliaRow>

        <SummaryList
          title={t('damage_report_form.step_summary.damage')}
          redirectToStep={1}
          dataTestid="step-summary-damage-section"
          items={renderDamageSummaryItems()}
        />

        <SummaryList
          title={t('damage_report_form.step_summary.place')}
          redirectToStep={2}
          dataTestid="step-summary-place-section"
          items={placeSummaryItems}
        />

        <SummaryList
          title={t('damage_report_form.step_summary.reporter')}
          redirectToStep={3}
          dataTestid="step-summary-reporter-section"
          items={reporterSummaryItems}
        />

        <SummaryList
          title={t('damage_report_form.step_summary.instructors_on_site')}
          redirectToStep={3}
          dataTestid="step-summary-instructors-on-site-section"
          items={instructorsOnSiteSummaryItems}
        />
      </TeliaForm>
    </Card>
  );
}
