import { TeliaCol, TeliaHeading, TeliaRow, TeliaNotification } from '@teliads/components/react';
import './StepOneForm.scss';
import { useTranslation } from 'react-i18next';

import AirCable from './forms/AirCable';
import Cabinet from './forms/Cabinet';
import GroundCable from './forms/GroundCable';
import Pole from './forms/Pole';
import { DamageCategoryOptions } from './models';

import ChipChoice from 'components/custom/chip-choice/ChipChoice';
import { useDamageReportForm } from 'contexts/damage-report-form.context';

export default function StepOneForm() {
  const { stepOne, setStepOne } = useDamageReportForm();
  const { t } = useTranslation();

  function updateInput(
    which: keyof typeof stepOne,
    value: string | Record<string, string> | boolean
  ) {
    setStepOne((prev) => ({
      ...prev,
      [which]: value,
    }));
  }

  function renderCategorySpecificFields() {
    switch (stepOne.damageCategory) {
      case 'GROUND_CABLE':
        return <GroundCable updateInput={updateInput} />;
      case 'AIR_CABLE':
        return <AirCable updateInput={updateInput} />;
      case 'CABINET':
        return <Cabinet updateInput={updateInput} />;
      case 'TECH_BUILDING':
        return (
          <>
            <TeliaNotification class="step-one-form__notification" heading-tag="h3">
              <span slot="heading">Information</span>
              <span slot="content">
                {t('damage_report_form.step_one.tech_building_notification')}{' '}
                <a href="tel:020463030">020-46 30 30</a>
              </span>
            </TeliaNotification>
          </>
        );
      case 'POLE':
        return <Pole updateInput={updateInput} />;
      case 'WELL':
        return (
          <TeliaNotification class="step-one-form__notification" heading-tag="h3">
            <span slot="heading">Information</span>
            {/* Render the translated content with clickable link */}
            <span
              slot="content"
              dangerouslySetInnerHTML={{
                __html: t('damage_report_form.step_one.well_notification'),
              }}
            />
          </TeliaNotification>
        );
      default:
        return null;
    }
  }

  return (
    <form name="step-one-form" className="step-one-form">
      <TeliaHeading variant="title-200" tag="h2">
        {t('damage_report_form.step_one.title')}
      </TeliaHeading>

      <TeliaRow>
        <TeliaCol width={12}>
          <TeliaHeading variant="subsection-100" tag="h3">
            {`* 1. ${t('damage_report_form.step_one.damage_category')}?`}
          </TeliaHeading>
        </TeliaCol>
      </TeliaRow>

      <TeliaRow>
        <TeliaCol width={12} className="step-one-form__chips">
          {Object.entries(DamageCategoryOptions).map(([key, category]) => (
            <ChipChoice
              key={key}
              checked={key === stepOne.damageCategory}
              onClick={() => {
                updateInput('damageCategory', key);
              }}
              dataTestid="chip-choice"
            >
              {t(category)}
            </ChipChoice>
          ))}
        </TeliaCol>
      </TeliaRow>

      {stepOne.damageCategory && renderCategorySpecificFields()}
    </form>
  );
}
