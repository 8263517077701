import { TeliaCol, TeliaFooter, TeliaRow } from '@teliads/components/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Heading from '../heading/Heading';
import Paragraph from '../paragraph/Paragraph';

//@ts-ignore
import { ReactComponent as TeliaFooterIcon } from './TeliaFooterIcon.svg';

import './TheFooter.scss';

function cookieSettingsScriptInit() {
  // @ts-ignore
  window.OneTrust.ToggleInfoDisplay();
}

export const TheFooter = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const links = document.querySelectorAll('a');

    links.forEach((link) => {
      if (link.href && link.href.startsWith('https://')) {
        const linkUrl = new URL(link.href);
        const hostUrl = new URL(process.env.REACT_APP_HOST || window.location.origin);

        if (linkUrl.hostname !== hostUrl.hostname) {
          link.setAttribute('target', '_blank');
        }
      }
    });
  }, []);

  const translatedColumns = [
    {
      heading: t('footer.column_one.heading'),
      content: t('footer.column_one.content', { returnObjects: true }) as string[],
    },
    {
      heading: t('footer.column_two.heading'),
      content: t('footer.column_two.links', { returnObjects: true }) as {
        title: string;
        href: string;
      }[],
    },
    {
      heading: t('footer.column_three.heading'),
      content: t('footer.column_three.links', { returnObjects: true }) as {
        title: string;
        href: string;
      }[],
    },
    {
      heading: t('footer.column_four.heading'),
      content: t('footer.column_four.content', { returnObjects: true }) as string[],
    },
  ];

  return (
    <div className="the-footer">
      <TeliaFooter>
        <a slot="logo" href={`/${i18n.language}`} aria-label={t('footer.telia_icon')}>
          <TeliaFooterIcon className="logo" />
        </a>

        <TeliaRow slot="navigation" className="footer-content">
          {translatedColumns.map((column) => (
            <TeliaCol
              key={column.heading}
              width={12}
              widthLg={3}
              className="footer-content__column"
            >
              <Heading variant="title-100" tag="h3" className="footer-content__section-title">
                {column.heading}
              </Heading>

              {column.content.map((info) => (
                <Paragraph key={JSON.stringify(info)} variant="paragraph-100">
                  {typeof info === 'string' ? (
                    info
                  ) : (
                    <Link className="footer-content__link" to={info.href}>
                      {info.title}
                    </Link>
                  )}
                </Paragraph>
              ))}
            </TeliaCol>
          ))}
        </TeliaRow>

        <section slot="legal" className="legal-row">
          <p className="legal-row__legal-text">
            &copy; Telia Company AB 556103-4249 Stjärntorget 1 Solna
          </p>
          <ul className="legal-row-consent-list">
            <li className="legal-row-consent-list__item">
              <a
                className="legal-row-consent-list__item-value"
                href="#"
                onClick={cookieSettingsScriptInit}
              >
                {t('footer.cookie_consent')}
              </a>
            </li>
          </ul>
        </section>
      </TeliaFooter>
    </div>
  );
};
