import { TeliaButton, TeliaIcon } from '@teliads/components/react';
import { ChangeEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Heading from 'components/voca/heading/Heading';
import { useDamageReportForm } from 'contexts/damage-report-form.context';
import './FileUpload.scss';

const HOST = process.env.REACT_APP_HOST;

interface FileUploadProps {
  isExpanded: boolean;
}

const SIZE_50MB = 50 * 1024 * 1024;
const IMAGE_JPEG = 'image/jpeg';
const IMAGE_PNG = 'image/png';
const IMAGE_HEIC = 'image/heic';
const DOCUMENT_XLS = 'application/vnd.ms-excel';
const DOCUMENT_XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const DOCUMENT_PDF = 'application/pdf';
const ALLOWED_FILE_TYPES = [
  IMAGE_JPEG,
  IMAGE_PNG,
  IMAGE_HEIC,
  DOCUMENT_XLS,
  DOCUMENT_XLSX,
  DOCUMENT_PDF,
];
const ALLOWED_FILE_TYPES_HTML = '.jpg, .png, .jpeg, .heic, .xls, .xlsx, .pdf';
export default function FileUpload({ isExpanded }: FileUploadProps) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string>();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { selectedFiles, setSelectedFiles } = useDamageReportForm();
  const { sessionId } = useDamageReportForm();

  function calculateTotalSize(files: File[]): number {
    return files.reduce((totalSize, file) => totalSize + file.size, 0);
  }

  function handleFileChange(e: ChangeEvent<HTMLInputElement>) {
    const newFiles = Array.from(e.target.files as FileList);

    // Reset the file input to allow the same file to be uploaded again
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    const totalSize = calculateTotalSize([...selectedFiles, ...newFiles]);

    if (totalSize > SIZE_50MB) {
      setErrorMessage(t('error_message.size'));
      return;
    }

    const validFiles = newFiles
      .map((file: File) => {
        const isImage = ALLOWED_FILE_TYPES.includes(file.type);
        if (!isImage) {
          setErrorMessage(t('error_message.type'));
          return null;
        }

        return handleDuplicateFileName(file);
      })
      .filter(Boolean) as File[];

    uploadImageToServer(validFiles);

    setSelectedFiles([...selectedFiles, ...validFiles]);
  }

  function handleDuplicateFileName(file: File): File {
    const fileName = file.name;
    const extension = fileName.substring(fileName.lastIndexOf('.'));
    const baseName = fileName.substring(0, fileName.lastIndexOf('.'));

    let duplicateCount = 0;
    let newFileName = fileName;

    while (selectedFiles.some((existingFile) => existingFile.name === newFileName)) {
      duplicateCount += 1;
      newFileName = `${baseName}(${duplicateCount})${extension}`;
    }

    if (newFileName !== fileName) {
      return new File([file], newFileName, { type: file.type, lastModified: file.lastModified });
    }

    return file;
  }

  function removeFile(index: number) {
    const updatedFiles = [...selectedFiles];

    updatedFiles.splice(index, 1);

    setSelectedFiles(updatedFiles);
    setErrorMessage('');
  }

  function initNativeFileInputClick() {
    if (fileInputRef.current) {
      fileInputRef.current.click();
      errorMessage && setErrorMessage('');
    }
  }

  function getFileSizeInMB(sizeInBytes: number): string {
    let sizeInMB = sizeInBytes / (1024 * 1024);
    if (sizeInMB > 0 && sizeInMB < 0.01) {
      sizeInMB = 0.01;
    }
    return `${sizeInMB.toFixed(2)} mb`;
  }

  function uploadImageToServer(files: File[]) {
    files.forEach(async (file) => {
      const fileName = file.name;
      const fileContentType = file.type;

      try {
        const response = await fetch(
          HOST +
            `/api/report?fileName=${encodeURIComponent(
              fileName
            )}&id=${sessionId}&contentType=${encodeURIComponent(fileContentType)}`,
          {
            method: 'GET',
          }
        );

        const data = await response.json();

        const uploadUrl = data.url;

        const putResponse = await fetch(uploadUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': file.type,
          },
          body: file,
        });

        if (!putResponse.ok) {
          throw new Error('Failed to upload the file');
        }
      } catch (error) {
        console.error('Error during file upload:', error);
      }
    });
  }

  return (
    <div title="upload" className={`file-upload ${isExpanded ? 'file-upload--expanded' : ''}`}>
      <Heading className="file-upload__heading" variant="title-100" tag="h2">
        {t('damage_report_form.upload')}
      </Heading>

      <input
        title="input"
        type="file"
        accept={ALLOWED_FILE_TYPES_HTML}
        onChange={handleFileChange}
        ref={fileInputRef}
      />

      {errorMessage && <p className="file-upload__error-message">{errorMessage}</p>}

      <ul>
        {selectedFiles.map((file, index) => (
          <li key={index}>
            <TeliaIcon
              dataTestid="remove-button"
              className="icon"
              size="md"
              name="delete"
              onClick={() => removeFile(index)}
            />
            <span className="file-name">{file.name}</span>
            <span className="file-size">{getFileSizeInMB(file.size)}</span>
          </li>
        ))}
      </ul>

      <div className="file-upload__button">
        <TeliaButton variant="text" size="md" onClick={initNativeFileInputClick}>
          <TeliaIcon slot="left" size="sm" name="plus" />
          {t('action.upload')}
        </TeliaButton>
      </div>

      <p className="file-upload__max-message">Max 50 MB</p>
    </div>
  );
}
